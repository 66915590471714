import { Button, Upload } from 'antd';
import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { UploadOutlined } from '@ant-design/icons';

const ExcelRender = ({header, setHeader, cols, setCols}) => {

    const handleFile = (event) => {
        const file = event.file
        ExcelRenderer(file, (err, response) => {
            if(err){
                console.log(err)
            }else{
                setHeader(response.rows[0])
                setCols(response.rows)
            }
        })
    }
return (
    <>
    <Upload beforeUpload maxCount={1} onChange={handleFile} >
        <Button icon={<UploadOutlined />}>Upload File</Button>
    </Upload>
    <div className='relative overflow-auto border mt-4 sm:rounded-lg h-[519px]'>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 overflow-auto">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    {header.map((h, i) => (
                        <th scope='col' class="px-6 py-3 bg-gray-50 dark:bg-gray-800" key={i}>{h}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {cols.slice(1).map((col, i) => (
                    <tr className='bg-white border-b dark:bg-gray-900 dark:border-gray-700' key={i}>
                        {col.map((c, i) => (
                            <td className="px-6 py-4" key={i}>{c}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    </>
  )
}

export default ExcelRender