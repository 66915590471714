import Reacyarnt, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Form, Input, Collapse, Tabs, DatePicker, Select, Upload, Button, InputNumber, Divider, TreeSelect, Switch, Checkbox, Space } from 'antd';
import ISelect from 'components/Select';
import Map from 'components/Map';
import TinyEditor from 'components/TinyEditor';
import dayjs from 'dayjs';

const { Panel } = Collapse;

const CustomForm = ({fields, className, editData, length, ...restProps}) => { 
  const [ value, setValue ] = useState(null);
  const [antForm] = Form.useForm();
  const form = restProps.form || antForm

  useEffect(() => {
    if(editData){
      form.setFieldsValue(editData)
      fields.map((item) => {
        if(item.type === 'date'){
          form.setFieldValue(item.name, dayjs(editData[item.name]))
        }
      })
    };
  },[editData]);

  let date = dayjs().format('YYYY-MM-DD');
  const getFillPercent = (fields) => {
    let values = form.getFieldsValue()
    let filled = 0;
    let percent = 0
    if(fields){
      fields.map((field) => {
        if(values[`${field.name}`]){
          filled += 1
        }
      })
      percent = parseInt((filled * 100)/fields.length);
    }
    return `${percent} %`
  }
  
  const renderFormItem = (_field, _fieldIndex) => {
    switch (_field.type) {
      case 'collapse':
        return (
          <Collapse defaultActiveKey={['0']} key={`form-collapse-${_fieldIndex}`} {..._field} >
            {
              _field.children?.map((item, itemIndex) => (
                <Panel 
                  key={`${itemIndex}`} 
                  header={
                    <Form.Item className='mb-0' shouldUpdate={(pre, cur) => pre[item.name] !== cur[item.name]}>
                      <span>{item.label} - {getFillPercent(item.children)}</span>
                    </Form.Item>
                  }
                >
                  <div className="grid grid-cols-12 gap-4">
                    {
                      item.children && item.children.map((child, childIndex) => (
                        renderFormItem(child, childIndex)
                      ))
                    }
                  </div>
                </Panel>
              ))
            }
          </Collapse>
        );
      case 'tabs':
        return (
          <Tabs>
          </Tabs>
        )
      case 'editor':
        return (
          // <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field} >
          <Form.Item name="content" {..._field}>
            <TinyEditor editData={editData} name={_field.name} disabled={restProps.disabled} {..._field.inputProps} form={form} />
          </Form.Item>
          // </Form.Item>
        )
      case 'dependent':
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentName] !== curValue[_field.dependentName]} className={_field.className} >
            { 
              ({getFieldValue}) => (
                <>
                  {
                    getFieldValue(_field.dependentName) && 
                    (getFieldValue(_field.dependentName) === _field.dependentValue) ?
                    _field.children && _field.children.map((child, childIndex) => (
                      renderFormItem(child, childIndex)
                    )) 
                    :
                    null
                  }
                </>
              )
            }
          </Form.Item>
        )
      case 'date': 
        return (
          <Form.Item 
            key={`form-item-${_field.name}-${_fieldIndex}`} 
            {..._field}
          >
            <DatePicker
              changeOnBlur={true}
              size='small'
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'rangeDate':
      if(_field.name === 'date' && !value){
        form.setFieldValue('date', [dayjs(date + " 00:00:00"), dayjs(date + " 23:59:59")])
      }
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <DatePicker.RangePicker
              onChange={(e) => setValue(e)}
              showTime={{format: 'HH:mm'}}
              size='small' 
              className='w-full'
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'select': 
        if(_field.dependentIndex) {
          return (
            <Form.Item noStyle shouldUpdate={(preValue, curValue) => preValue[_field.dependentIndex] !== curValue[_field.dependentIndex]} className={_field.className}>
              { ({getFieldValue, setFieldValue}) => {
                return (
                  <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
                    <ISelect
                      setFieldValue={setFieldValue}
                      getFieldValue={getFieldValue}
                      _field={_field}
                      optionsUrl={_field.inputProps.optionsUrl}
                      dependentValue={getFieldValue(_field.dependentIndex)}
                      placeholder={`${_field.label} Select`}
                      {..._field.inputProps}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          )
        }
        else {
          // if(_field.name === 'status' && !editData){
          //   form.setFieldValue('status', 1)
          // }
          return (
            <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Select 
                size='small'
                allowClear
                {..._field.inputProps}
              />
            </Form.Item>
          )
        }
      case 'inputSelect': 
      return (
        <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Select
              size='small'
              showSearch
              allowClear
              filterOption={(input, option) => option.text.toLowerCase().includes(input.toLowerCase())}
              {..._field.inputProps} />
        </Form.Item>
      )
      case 'treeSelect': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <TreeSelect treeDefaultExpandAll {..._field.inputProps}/>
          </Form.Item>
        )
      case 'map': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Map {..._field.inputProps} value={form.getFieldValue(_field.name)} onChange={form.setFieldValue} disabled={restProps.disabled}/>
          </Form.Item>
        )
      case 'file': 
        return (
          <div className={`flex gap-5  items-start ${_field.className}`}>
            {editData && editData[_field.name] ? 
              <img className='max-w-[100px]' src={`${process.env.REACT_APP_CDN_URL}/${editData[_field.name]}`}/>
              :
              null
            }
            <Form.Item valuePropName="file" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Upload beforeUpload maxCount={1} {..._field.inputProps}>
                  <Button size='small' icon={<UploadOutlined />}>Upload</Button>
                </Upload>
            </Form.Item>
          </div>
        )
      case 'listFile': 
        return (
          <Form.Item valuePropName="file" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
              <Upload beforeUpload {..._field.inputProps}>
                <Button size='small' icon={<UploadOutlined />}>Upload</Button>
              </Upload>
          </Form.Item>
        )
      case 'password': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.Password />
          </Form.Item>
        )
      case 'switch': 
        return (
          <Form.Item valuePropName="checked" key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Switch size='small' />
          </Form.Item>
        )
      case 'textarea': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <Input.TextArea size='small' placeholder={`${_field.label}`} {..._field.inputProps} />
          </Form.Item>
        )
      case 'number': 
        if(_field.name === 'orderid' && !editData){
          form.setFieldValue(_field.name, length+1)
        }
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber
              size='small'
              controls={false}
              placeholder={`${_field.label}`}
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'price': 
        return(
          <Form.Item 
            key={`form-item-${_field.name}-${_fieldIndex}`}
            {..._field}
          >
            <InputNumber
              size='small'
              controls={false}
              formatter={value => `₮ ${new Intl.NumberFormat('en-US').format(value)}`}
              placeholder={`${_field.label}`}
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'priceCheck': 
        return(
          <Space className={`flex flex-col ${_field.className} items-start`}>
          <Form.Item 
            key={`form-item-${_field.name}-${_fieldIndex}`}
            name={_field.name}
            valuePropName="checked"
            className={_field.className}
          >
            <Checkbox>
              {_field.label}
            </Checkbox>
          </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev[_field.name] != next[_field.name]}>
              {({getFieldValue, setFieldValue}) => {
                if(getFieldValue(_field.name) == true) {
                  setFieldValue(`${_field.name}`, _field.inputProps.amount)
                } 
                return (
                  <Form.Item name={`${_field.name}`}>
                    <InputNumber
                      size='small'
                      rules={_field.rules}
                      controls={false}
                      formatter={value => `₮ ${new Intl.NumberFormat('en-US').format(value)}`}
                      placeholder={`${_field.label}`}
                      {..._field.inputProps}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Space>
        )
      case 'percent': 
        return (
          <Form.Item key={`form-item-${_field.name}-${_fieldIndex}`} {..._field}>
            <InputNumber
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
              placeholder={`${_field.label} оруулах`} 
              {..._field.inputProps}
            />
          </Form.Item>
        )
      case 'checkbox':
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            valuePropName="checked"
            {..._field}
            label=''
          >
            <Checkbox
              {..._field.inputProps}
            >
              {_field.label}
            </Checkbox>
          </Form.Item>
        );
      case 'component': 
        return (_field.component)
      case 'divider': 
        return (
          <Form.Item {..._field}>
            <Divider key={`divider-${_field.label}`} {..._field.inputProps} >{_field.text}</Divider> 
          </Form.Item>
        )
      default:
        return (
          <Form.Item
            key={`form-item-${_field.name}-${_fieldIndex}`}
            {..._field}>
            <Input size='small' placeholder={`${_field.label}`} {..._field.inputProps} />
          </Form.Item>
        )
    }
  }

  return (
    <Form
      className={`grid grid-cols-12 ${className}`}
      {...restProps}
    >
      {
        fields.map((item, itemIndex) => renderFormItem(item, itemIndex))
      }
    </Form>
  );
}

CustomForm.propTypes = {
  fields: PropTypes.array,
}

CustomForm.defaultProps = {
  fields: [],
}


export default CustomForm;
