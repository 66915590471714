import React, { useEffect, useState } from 'react';
import { Form as AntForm, Modal, Dropdown, Menu, Button, Collapse, Input, Select, InputNumber } from 'antd';
import {
  EditOutlined,
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { Form, MainTable } from 'components';
import { instance } from 'utils/axios';
import { Link } from 'react-router-dom';
import searchFields from './searchFields';
import statusFields from '../registration/statusFields';
import { AuthContext } from 'contexts';
import dayjs from 'dayjs';

const { Panel } = Collapse;

function Registration() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [filterForm] = AntForm.useForm();
  const [statusForm] = AntForm.useForm();
  const {state, action} = React.useContext(AuthContext);
  const [ selectedKeys, setSelectedKeys ] = useState([]);
  const [ selectedRows, setSelectedRows ] = useState([]);
  const [ editModalOpen, setEditModalOpen ] = useState(false)
  const [ editForm ] = AntForm.useForm()

  const answer = [
    { label: "Ирээгүй", text: "Ирээгүй", value: "0" },
    { label: "Зөвшөөрсөн", text: "Зөвшөөрсөн", value: "1" },
    { label: "Татгалзсан", text: "Татгалзсан", value: "2" },
  ];
  const defaultCheckedColumns = [
    'No',
    'burtgel_number',
    'first_name',
    'hariu_date',
    'hariu_irsen_eseh',
    'last_name',
    'visa_type',
    'name',
    'phone_number',
    'username',
    'receive_date',
    'registeration_number',
    'tailbar',
    'tusgai',
    'action',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: 'get',
      url: '/burtgel',
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };

  const handleDelete = (id) => {
    modal.confirm({
      title: 'Анхаарна уу !',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `burtgel/${id}`,
          }).then((res) => {
            resolve();
          }).catch((err) => {
            reject();
          }).then(() => fetchData())
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 40,
      align: 'center',
      render: (text, row, i) => (
        <span>{currentPage + i + 1}</span>
      )
    },
    {
      title: 'Овог',
      dataIndex: 'last_name',
      key: 'last_name',
      filterType: "search",
    },
    {
      title: 'Нэр',
      dataIndex: 'first_name',
      key: 'first_name',
      filterType: "search",
    },
    {
      title: 'Бүртгэлийн дугаар',
      dataIndex: 'burtgel_number',
      key: 'burtgel_number',
      filterType: "search",
    },
    {
      title: 'Материал авсан',
      dataIndex: 'receive_date',
      key: 'receive_date',
      filterType: 'search',
    },
    {
      title: 'Хариу авах',
      dataIndex: 'hariu_date',
      key: 'hariu_date',
      filterType: 'search',
    },
    {
      title: 'Регистр',
      dataIndex: 'registeration_number',
      key: 'registeration_number',
      filterType: 'search',
    },
    {
      title: 'Визний төрөл',
      dataIndex: 'visa_type',
      key: 'visa_type',
      filterType: 'search',
    },
    {
      title: 'Утас 1',
      dataIndex: 'phone_number',
      key: 'phone_number',
      filterType: 'search',
    },
    {
      title: 'Тусгай тэмдэглэгээ',
      dataIndex: 'tusgai',
      key: 'tusgai',
      filterType: 'search',
    },
    {
      title: 'Хариу',
      dataIndex: 'hariu_irsen_eseh',
      key: 'hariu_irsen_eseh',
      filterType: 'search',
      render: (text) => {
        const answerFind = answer.find((item) => item?.value === text?.toString())
        return <div className='text-xs'>{answerFind?.text}</div>
      }
    },
    {
      title: 'Хэрэглэгчийн нэр',
      dataIndex: 'username',
      key: 'username',
      filterType: 'search',
    },
    {
      title: 'Тэмдэглэгээ',
      dataIndex: 'tailbar',
      key: 'tailbar',
      filterType: 'search',
    },
    {
      title: 'Үйлдэл',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 80,
      render: (text, row) => (
        <div className='flex justify-center w-full'>
          <Dropdown
            placement='bottomRight'
            overlay={
              <Menu>
                <Menu.Item key='action-1'>
                  <Link to={`/admin/registration/${row.id}`}>
                    <div className='flex items-center gap-2'><EditOutlined /> Засах</div>
                  </Link>
                </Menu.Item>
                <Menu.Item danger className='flex items-center gap-2' onClick={() => handleDelete(row.id)} key='action-2'>
                  <div className='flex items-center gap-2'><DeleteOutlined /> Устгах</div>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size='small' className='flex items-center' >
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  ];

  const handleChangeFinish = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: `/burtgel_hariu`,
      data: values
    }).then(res => {
      setEditModalOpen(false)
      editForm.resetFields()
    }).catch(err => {

    }).then(() => {
      setLoading(false)
      fetchData()
    })
  }

  const handleFilterSubmit = (values) => {
    setLoading(true);
    instance({
      method: 'get',
      url: `/burtgelsearch?year=${values.year ? values.year : null}&tusgai=${values.tusgai ? values.tusgai : null}&burtgel_number=${values.burtgel_number ? values.burtgel_number : null}&first_name=${values.first_name ? values.first_name : null}&registeration_number=${values.registeration_number ? values.registeration_number : null}&phone_number=${values.phone_number ? values.phone_number : null}&hariu_irsen_eseh=${values.hariu_irsen_eseh ? values.hariu_irsen_eseh : null}&sdate=${values.receive_date ? values.receive_date[0].format("YYYY/MM/DD") : null}&edate=${values.receive_date ? values.receive_date[1].format("YYYY/MM/DD") : null}`,
    }).then((res) => {
      setData(res.data);
    }).catch((err) => {
    }).then(() => setLoading(false))
  };
  const handleSelectedSubmit = (values) => {
    setLoading(true);
    let tmp = []
    selectedRows.map((item) => tmp.push(item.burtgel_number))
      instance({
        method: 'put',
        url: '/burtgel_hariu',
        data: {
          ...values,
          burtgel_number: selectedRows.length > 0 && tmp,
        }
      }).then((res) => {
        setSelectedKeys([]);
        setSelectedRows([]);
        fetchData();
      }).catch((err) => {
      }).then(() => setLoading(false))
  }

  return (
    <>
      <div className='flex justify-between items-center pb-2'>
        <div className='text-primary'>Бүртгэл</div>
        <div className="flex gap-3">
          {
            (state.userInfo.customer.role === "Хариу олгогч" || state.userInfo.customer.role === "Админ") &&
            <Button icon={<EditOutlined />} type='primary' size='small' onClick={e => setEditModalOpen(true)}>Засварлах</Button>
          }
          <Link to={'/admin/registration/add'}>
            <Button icon={<PlusOutlined />} type='primary' size='small'>Нэмэх</Button>
          </Link>
        </div>
      </div>
      <Collapse
        bordered={true}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        expandIconPosition='right'
        defaultActiveKey={['0']}
        ghost={true}
        className='grid-cols-12 mb-4'
      >
        <Panel
          header={
            <div className='flex items-center gap-1 font-medium'>
              <SearchOutlined />
              Хайлт
            </div>
          }
          className='bg-white border border-gray-200'
        >
          <Form
            form={filterForm}
            fields={searchFields({
              answer: answer,
            })}
            initialValues={{
              receive_date: [dayjs().add(-1, 'day'), dayjs()]
            }}
            length={data.length}
            layout="vertical"
            onFinish={handleFilterSubmit}
            className='gap-4'
          />
          <div className="flex justify-end gap-2 mt-4">
            <Button className='flex items-center' size='small' type="default" onClick={() => filterForm.submit()}>
              <SearchOutlined />Хайх
            </Button>
            <Button className='flex items-center' size='small' type="default" onClick={() => filterForm.resetFields()}>
              <ClearOutlined />Цэвэрлэх
            </Button>
          </div>
        </Panel>
      </Collapse>
      {
        state.userInfo.customer.role === "Хариу олгогч" &&
        <div className='bg-white border border-gray-200 p-4 rounded-lg mb-4'>
          <h3 className='font-semibold'>Олноор төлөв өөрчлөх</h3>
          <Form
            form={statusForm}
            fields={statusFields({
              answer: answer,
            })}
            layout="vertical"
            onFinish={handleSelectedSubmit}
            className='gap-4'
          />
          <div className="flex justify-end gap-2 mt-4">
              <Button className='flex items-center' size='small' type="primary" onClick={() => statusForm.submit()}>
                Хадгалах
              </Button>
              <Button className='flex items-center' size='small' type="default" onClick={() => setSelectedKeys([])}>
                Цэвэрлэх
              </Button>
          </div> 
        </div>
      }
      <Modal
        title="Бүртгэл"
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        onOk={() => editForm.submit()}
        confirmLoading={loading}
        destroyOnClose={true}>
        <AntForm
          disabled={loading}
          form={editForm}
          layout="vertical"
          onFinish={handleChangeFinish}>
          <AntForm.Item label="Жил (Бүртгүүлсэн огноогоор)" name="year">
            <InputNumber className="w-full">
            </InputNumber>
          </AntForm.Item>
          <AntForm.Item label="Зөвшөөрсөн" name='approved'>
            <Select mode="tags">
            </Select>
          </AntForm.Item>
          <AntForm.Item label="Татгалзсан" name="rejected">
            <Select mode="tags">
            </Select>
          </AntForm.Item>
        </AntForm>
      </Modal>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        defaultCheckedColumns={defaultCheckedColumns}
        filterHide={false}
        pagination={false}
        rowSelectionShow={state.userInfo.customer.role === "Хариу олгогч" ? true : false} 
        setSelectedKeys={setSelectedKeys}
        setSelectedRows={setSelectedRows}
        selectedKeys={selectedKeys}
      />
      {contextHolder}
    </>
  );
};

export default Registration;